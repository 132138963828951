import { useState } from 'react';
import { Descriptions } from 'antd';
import { useMount } from 'ahooks';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import type { DescriptionsProps } from 'antd';
import './App.css';

interface IpProps {
  ip: string;
  network: string;
  asn: string;
  org: string;
  country_name: string;
  country_code: string;
  region: string;
  region_code: string;
  city: string;
}

interface FingerPrintProps {
  visitorId: string;
  language: string;
  platform: string;
  screenResolution: number[];
  timezone: string;
}

function App() {
  const [ip, setIp] = useState<IpProps>({
    ip: '',
    network: '',
    asn: '',
    org: '',
    country_name: '',
    country_code: '',
    region: '',
    region_code: '',
    city: '',
  });

  const [fingerPrint, setFingerPrint] = useState<FingerPrintProps>({
    visitorId: '',
    language: '',
    platform: '',
    screenResolution: [],
    timezone: '',
  });

  useMount(() => {
    axios.get('https://ipapi.co/json/').then((res) => {
      setIp(res.data);
    }).catch((err) => {
      console.log(err);
    });

    FingerprintJS.load().then(
      fp => fp.get()
    ).then(
      result => {
        setFingerPrint({
          visitorId: result.visitorId,
          language: (result.components.languages as any).value[0],
          platform: (result.components.platform as any).value,
          screenResolution: (result.components.screenResolution as any).value,
          timezone: (result.components.timezone as any).value,
        });
      }
    );
  });

  const items: DescriptionsProps['items'] = [
    {
      key: 'ip',
      label: 'IP Address',
      children: ip.ip,
    },
    {
      key: 'network',
      label: 'Network',
      children: ip.ip && `${ip.network} [${ip.asn}] (${ip.org})`,
    },
    {
      key: 'location',
      label: 'Location',
      children: ip.ip && `${ip.country_name} (${ip.country_code}) - ${ip.region} (${ip.region_code}) - ${ip.city}`,
    },
    {
      key: 'platform',
      label: 'Platform',
      children: `${fingerPrint.platform}`,
    },
    {
      key: 'resolution',
      label: 'Resolution',
      children: fingerPrint.visitorId && `${fingerPrint.screenResolution[0]}x${fingerPrint.screenResolution[1]}`,
    },
    {
      key: 'timezone',
      label: 'Timezone',
      children: `${fingerPrint.timezone}`,
    },
    {
      key: 'language',
      label: 'Language',
      children: `${fingerPrint.language}`,
    },
    {
      key: 'visitorId',
      label: 'Visitor ID',
      children: fingerPrint.visitorId,
    },
    {
      key: 'useragent',
      label: 'UserAgent',
      children: window.navigator.userAgent,
    },
  ];

  return (
    <div className='App'>
      <Descriptions
        className='descriptions'
        bordered
        column={1}
        items={items}
      />
    </div>
  );
}

export default App;
